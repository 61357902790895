import {
  Icon,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { ChromePicker } from 'react-color';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CircularProgress from '@mui/material/CircularProgress';
import './QuickChat.css';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { capitalize } from 'lodash';
import quickChatQuery from '../../query/quickChat';
import {
  avatarDataSendToServer,
  capitalizeFirstLetter,
  getDecryptData,
  handleApiRequest,
  handleWabaApiRequest,
} from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';
import BotStatus from './BotStatus';
import SharePost from '../quick-post/SharePost';
import wabaQuery from '../../query/waba';

const QuickChat = (props) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [quickChatCreate, setQuickChatCreate] = useState(false);
  const [quickChatEdit, setQuickChatEdit] = useState(false);
  const [avatarList, setAvatarList] = useState([]);
  const [copy, setCopy] = useState(false);
  const [imageUpload, setImageUpload] = useState();
  const [noImgMsg, setNoImgMsg] = useState('');
  const [domainStatus, setDomainStatus] = useState(null);
  const [domainLoading, setDomainLoading] = useState(false);
  const [domainMessage, setDomainMessage] = useState('');
  const [whatsAppPhoneNumberId, setWhatsAppPhoneNumberId] = useState('');
  const [domainAvailable, setDomainAvailable] = useState(false);
  const [selectAvatar, setSelectAvatar] = useState(false);
  const [quickChatActiveData, setQuickChatActiveData] = useState({});
  const [shareCompOpen, setShareCompOpen] = useState(false);
  const {
    setSelectAvatarUrl,
    setPageLoading,
    setQuickChatDetails,
    quickChatDetails,
    selectAvatarUrl,
    setImageUrl,
    color,
    setColor,
    imageUrl,
  } = props;

  const [botData, setBotData] = useState({
    botName: quickChatDetails?.name || 'Quick Chat',
    domainName:
      quickChatDetails?.domain ||
      `${
        userCurrentBusinessDetails?.city?.toLowerCase() ||
        userCurrentBusinessDetails?.countries?.name?.toLowerCase()
      }/${userCurrentBusinessDetails?.name
        ?.replace(/[\s-]+/g, '')
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()}`,
  });
  const [isEmpty, setIsEmpty] = useState({ botName: false, domainName: false });
  const [colorBox, setColorBox] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const fetchDomainNameAvailable = async () => {
      try {
        setDomainLoading(true);
        const payloadData = {
          query: quickChatQuery.checkQuickChatDomain,
          variables: {
            domain: botData?.domainName,
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setDomainLoading(false);
        const status = resultData?.checkQuickChatDomain?.status;
        setDomainStatus(status);

        if (status === 200) {
          setDomainAvailable(resultData?.checkQuickChatDomain?.data?.domainAvailable);
        }
        setDomainMessage(resultData?.checkQuickChatDomain?.message);
      } catch (error) {
        setDomainLoading(false);
        console.error('Error fetching domain availability:', error);
      }
    };
    if (quickChatCreate) {
      fetchDomainNameAvailable();
    }
  }, [botData?.domainName, enqueueSnackbar, quickChatCreate]);

  const fetchAgentSupportStatus = useCallback(async () => {
    const payloadData = {
      query: quickChatQuery.getQuickChatDefaultAvatar,
    };
    const resultData = await handleApiRequest(payloadData);
    if (resultData?.getQuickChatDefaultAvatar?.status === 200) {
      setAvatarList(resultData?.getQuickChatDefaultAvatar?.data);
      if (!quickChatEdit) {
        setSelectAvatarUrl(resultData?.getQuickChatDefaultAvatar?.data[0]?.avatar);
      }
    }
  }, [quickChatEdit, setSelectAvatarUrl]);

  useEffect(() => {
    if (avatarList?.length === 0) {
      fetchAgentSupportStatus();
    }
  }, [avatarList?.length, fetchAgentSupportStatus]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.name) {
      const countryName =
        userCurrentBusinessDetails?.countries?.name
          ?.toLowerCase()
          .replace(/[\s-]+/g, '')
          .replace(/[^a-zA-Z]/g, '') || '';

      const businessName =
        userCurrentBusinessDetails?.name
          ?.toLowerCase()
          .replace(/[\s-]+/g, '')
          .replace(/[^a-zA-Z]/g, '') || '';

      const sanitizedDomainName = quickChatDetails?.domain || `${countryName}/${businessName}`;

      setBotData((prevBotData) => ({
        ...prevBotData,
        domainName: sanitizedDomainName,
      }));
    }
  }, [
    userCurrentBusinessDetails?.name,
    userCurrentBusinessDetails?.countries?.name,
    quickChatDetails?.domain,
  ]);
  const handleInputChange = (field) => async (e) => {
    let { value } = e.target;
    if (field === 'domainName') {
      value = value.replace(/[^a-zA-Z./-]/g, '');
    }
    if (field === 'botName') {
      value = value.replace(/[^a-zA-Z\s]/g, '');
    }
    setBotData({ ...botData, [field]: value });
    setIsEmpty({ ...isEmpty, [field]: value.trim() === '' });
  };
  const handleCreateBot = async () => {
    setPageLoading(true);
    const payload = {
      query: quickChatQuery.createQuickChatAndUpdate,
      variables: {
        name: botData?.botName,
        headerColor: color,
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        domain: `${botData?.domainName || ''}`,
        ...(!imageUpload && {
          avatar: imageUrl || selectAvatarUrl,
        }),
        ...(imageUpload && {
          avatarImageFile: imageUpload,
        }),
      },
    };
    const result = await avatarDataSendToServer(payload);
    setQuickChatDetails('');
    if (result?.createQuickChatAndUpdate?.status === 200) {
      setPageLoading(false);
      setQuickChatEdit(false);
      setQuickChatCreate(false);
      const payloadData = {
        query: quickChatQuery.getQuickChat,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const resultData = await handleApiRequest(payloadData);
      if (
        resultData?.getQuickChat?.status === 200 &&
        resultData?.getQuickChat?.message !== 'Not found.'
      ) {
        setImageUpload();
        setImageUrl(resultData?.getQuickChat?.data?.avatar);
        setQuickChatDetails(resultData?.getQuickChat?.data);
      }
      enqueueSnackbar(result?.createQuickChatAndUpdate?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setQuickChatEdit(true);
    } else {
      setPageLoading(false);
      enqueueSnackbar(result?.createQuickChatAndUpdate?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
            },
          };
          const res = await handleWabaApiRequest(payload);
          if (res?.getUserDetail?.status === 200) {
            if (res?.getUserDetail?.data) {
              const decryptedData = JSON.parse(getDecryptData(res.getUserDetail.data));
              setWhatsAppPhoneNumberId(decryptedData?.whatsAppPhoneNumberId);
            }
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  const handleCopyClick = async (id) => {
    const textToCopy = document.getElementById(id).innerText;

    navigator.clipboard.writeText('').then(() => {
      setCopy(true);
      return navigator.clipboard.writeText(textToCopy);
    });
    await setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.onload = async () => {
      setImageUrl(reader.result);
      setImageUpload(file);
    };
    reader.readAsDataURL(file);
    setNoImgMsg('');
    setSelectAvatar(false);
  }

  const handleSelectAvtar = (value) => {
    setSelectAvatarUrl(value);
    if (value) {
      setImageUrl();
    }
  };

  const handleCloseComp = () => {
    setShareCompOpen(false);
  };

  return (
    <div>
      <div>
        <>
          {quickChatCreate ? (
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28">
              <div className="bg-white rounded-12">
                <div>
                  <div className="p-14 sm:p-24 flex flex-col-reverse sm:flex-row items-center sm:justify-between gap-20 sm:gap-0 mb-0 sm:mb-20">
                    <div className="mr-28 relative">
                      <Button
                        className="absolute left-[90px] top-[70px] sm:left-[150px] sm:top-[120px] transform -translate-x-1/2 -translate-y-1/2 rounded-full z-10 min-w-[26px] min-h-[26px] max-w-[26px] max-h-[26px] sm:min-w-[36px] sm:min-h-[36px] sm:max-w-[36px] sm:max-h-[36px] p-0 flex items-center justify-center"
                        variant="contained"
                        color="quickChat"
                        component="span"
                        onClick={() => setSelectAvatar(!selectAvatar)}
                      >
                        <EditIcon className="text-white text-16 sm:text-[20px]" />
                      </Button>
                      <img
                        src={imageUrl || selectAvatarUrl}
                        className="w-96 h-96 sm:w-160 sm:h-160 p-4 bg-indigo-50 bg-opacity-50 rounded-full object-cover"
                        alt="Avatar"
                      />
                    </div>

                    <div className="text-center flex  gap-10">
                      {quickChatEdit && (
                        <Button
                          type="button"
                          onClick={() => {
                            setQuickChatEdit(false);
                            setQuickChatCreate(false);
                            setBotData((prevBotData) => ({
                              ...prevBotData,
                              domainName: quickChatDetails?.domain,
                              botName: quickChatDetails?.name,
                            }));
                            setIsEmpty({ botName: false, domainName: false });
                            setImageUpload();
                            setImageUrl(quickChatDetails?.avatar);
                          }}
                          className="rounded-md w-full min-h-32 sm:min-h-auto max-h-32 sm:max-h-auto px-24 mx-auto"
                          variant="outlined"
                          color="quickChat"
                        >
                          {t('quickChat.chatbotDetail.cancel')}
                        </Button>
                      )}
                      <Button
                        type="button"
                        disabled={
                          isEmpty?.botName ||
                          isEmpty?.domainName ||
                          (domainAvailable && botData?.domainName === quickChatDetails?.domain) ||
                          domainStatus !== 200
                        }
                        onClick={() => handleCreateBot()}
                        className="rounded-md mx-auto min-h-32 sm:min-h-auto max-h-32 sm:max-h-auto px-24 w-full disabled:bg-gray disabled:text-black"
                        variant="contained"
                        color="quickChat"
                      >
                        {t('quickChat.chatbotDetail.save')}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-col quick-chat">
                    <div className="p-14 sm:p-24 w-full flex flex-col sm:flex-row items-center">
                      <InputLabel
                        htmlFor="botName"
                        className="text-14 w-full sm:w-1/2 font-500 text-grey-900 block"
                      >
                        {t('quickChat.createBot.name')}
                      </InputLabel>
                      <div className="flex flex-col w-full sm:w-1/2">
                        <TextField
                          onChange={handleInputChange('botName')}
                          value={botData?.botName}
                          size="small"
                          id="botName"
                          variant="outlined"
                          className="w-full"
                          required
                        />
                        {isEmpty?.botName && (
                          <div className="text-red pt-8 text-14 font-medium">
                            {t('quickChat.createBot.nameError')}
                          </div>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="p-14 sm:p-24 w-full flex flex-col sm:flex-row items-center">
                      <InputLabel
                        htmlFor="domainName"
                        className="text-14 w-full sm:w-1/2 font-500 text-grey-900 block"
                      >
                        {t('quickChat.createBot.domain')}
                      </InputLabel>
                      <div className=" w-full sm:w-1/2 relative flex  flex-col">
                        <TextField
                          onChange={handleInputChange('domainName')}
                          value={botData?.domainName}
                          size="small"
                          id="domainName"
                          variant="outlined"
                          required
                          fullWidth
                        />
                        {domainLoading && (
                          <CircularProgress
                            size={12}
                            className="text-darkgreen absolute mx-auto right-8 top-14 z-10"
                          />
                        )}
                        {(domainAvailable || botData?.domainName === quickChatDetails?.domain) && (
                          <span className="w-16 h-16 inline-block rounded-full align-middle text-center pt-2 absolute right-8 top-12 z-0 bg-darkgreen text-white text-12">
                            <Icon className="text-12">check</Icon>
                          </span>
                        )}
                        {(domainAvailable && botData?.domainName === quickChatDetails?.domain) ||
                          (domainStatus !== 200 && (
                            <Typography className="text-red">
                              {capitalize(domainMessage)}
                            </Typography>
                          ))}
                      </div>
                    </div>
                    <hr />
                    <div className="p-14 sm:p-24 w-full flex flex-col sm:flex-row items-center">
                      <InputLabel
                        htmlFor="domainName"
                        className="text-14 w-full sm:w-1/2 font-500 text-grey-900 block"
                      >
                        {t('quickChat.chatbotLink')}
                      </InputLabel>
                      <span className="lowercase w-full break-words sm:w-1/2 block">
                        {process.env.REACT_APP_PORTAL_URL}/quickChat/<b>{botData?.domainName}</b>
                      </span>
                    </div>
                    <hr />
                    <div className="p-14 sm:p-24 w-full flex flex-col">
                      <InputLabel
                        htmlFor="colorPicker"
                        className="text-14 font-500 text-grey-900 block"
                      >
                        {t('quickChat.createBot.selectColor')}
                      </InputLabel>
                      <div className="mt-20">
                        <span
                          className="w-44 h-44 inline-block rounded-full mr-16 align-middle text-center pt-8"
                          style={{ backgroundColor: color }}
                        >
                          <Icon className="text-white font-bold text-28">check</Icon>
                        </span>
                        <span
                          className="inline-block align-middle"
                          aria-hidden="true"
                          onClick={() => setColorBox(true)}
                        >
                          <img
                            src="/assets/images/business/color.png"
                            className="img-fluid w-44 h-44"
                            alt="..."
                          />
                        </span>
                        {colorBox && (
                          <div className="shadow p-16 inline-block absolute bg-white z-20 -ml-120 mt-60 sm:mt-0 sm:ml-16">
                            <Icon
                              className="text-14 absolute top-2 right-2 cursor-pointer"
                              onClick={() => setColorBox(false)}
                            >
                              close
                            </Icon>
                            <ChromePicker
                              color={color}
                              className="!shadow-none border border-1 border-solid border-grey-300"
                              onChangeComplete={(colors) => setColor(colors.hex)}
                            />
                            <div className="border border-1 border-solid border-grey-900 rounded-sm flex items-center p-5 mt-8">
                              <span
                                style={{
                                  backgroundColor: color,
                                  border: '1px solid #eee',
                                  width: 20,
                                  height: 20,
                                  color: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              />
                              <span className="inline-block ml-10 ">{color}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-12 p-24 text-center">
                <span className="block text-20 font-semibold text-center mb-24">
                  {t('quickChat.createBot.preview')}
                </span>
                <div className="relative inline-block mx-auto">
                  <div
                    className={`text-white absolute left-5 right-5 p-5 top-5 rounded-tl-2xl rounded-tr-2xl bg-[${color}]`}
                    style={{ backgroundColor: `${color}` }}
                  >
                    <div className="flex items-center">
                      <span className="inline-block w-32 h-32 bg-white rounded-full text-center pt-5 mr-6">
                        <img
                          src={imageUrl || selectAvatarUrl}
                          className="img-fluid w-20 h-20 mx-auto"
                          alt="..."
                        />
                        <span className="w-10 h-10 bg-green inline-block rounded-full ml-24 z-10 -mb-0 relative bottom-8" />
                      </span>
                      {botData?.botName}
                      <Icon className="text-12 float-right absolute right-8">close</Icon>
                    </div>
                  </div>
                  <div className="absolute top-56 left-8">
                    <span className="inline-block align-middle w-32 h-32 bg-darkgreen-100 rounded-full text-center pt-5 mr-6">
                      <img
                        src={imageUrl || selectAvatarUrl}
                        className="img-fluid w-20 h-20 mx-auto"
                        alt="..."
                      />
                    </span>
                    <div className="inline-block align-middle">
                      <div
                        className="dot w-6 h-6 rounded-full inline-block mx-2"
                        style={{ backgroundColor: `${color}` }}
                      />
                      <div
                        className="dot w-6 h-6 rounded-full inline-block mx-2"
                        style={{ backgroundColor: `${color}`, opacity: 0.5 }}
                      />
                      <div
                        className="dot w-6 h-6 rounded-full inline-block mx-2"
                        style={{ backgroundColor: `${color}`, opacity: 0.5 }}
                      />
                    </div>
                  </div>
                  <img
                    src="/assets/images/business/mobile_frame.png"
                    className="img-fluid max-w-256 w-full mx-auto mb-24"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-32 flex-col">
              <div className="bg-white max-w-640 rounded-md p-10 sm:p-20">
                <div className="flex items-center justify-between mb-20">
                  <h2 className="text-14 sm:text-16 font-semibold">
                    {t('quickChat.chatbotDetail.title')}
                  </h2>
                  <Button
                    className="min-w-28 min-h-28 hover:bg-transparent text-quick-chat bg-transparent md:ml-16 ml-5 inline-block text-14 sm:text-16"
                    type="button"
                    variant="contained"
                    size="small"
                    onClick={() => handleCopyClick('domain')}
                  >
                    <ContentCopyRoundedIcon className="text-16 sm:text-20" />{' '}
                    {copy
                      ? `${t('business.selectedBusinessDetail.labels.copied')} !`
                      : t('quickChat.chatbotDetail.copy')}
                  </Button>
                </div>
                <div className="">
                  <div className="flex flex-col items-center sm:flex-row">
                    <div className="sm:max-w-160 max-w-84 max-h-84 sm:max-h-160 w-full mr-28">
                      <img
                        src={quickChatDetails?.avatar}
                        alt="..."
                        className="w-84 h-84 sm:w-160 sm:h-160 p-4 bg-indigo-50 bg-opacity-50 rounded-full object-cover"
                      />
                    </div>
                    <div className="flex flex-col gap-10 w-full sm:w-400">
                      <h4 className="text-16 sm:text-20 font-500">{quickChatDetails?.name}</h4>
                      <span className="lowercase block relative">
                        <span
                          id="domain"
                          className="text-14 sm:text-16 font-500 inline-block break-all"
                        >
                          {process.env.REACT_APP_PORTAL_URL}/quickChat/
                          <span className="font-700">{quickChatDetails?.domain}</span>
                        </span>
                      </span>
                      <div className="flex flex-row gap-10">
                        <Button
                          onClick={() => setShareCompOpen(true)}
                          className="text-12 sm:text-16 w-full font-medium rounded-md py-2 px-4"
                          variant="outlined"
                          color="quickChat"
                          type="button"
                          size="small"
                        >
                          {t('qrScanner.button.share')}
                        </Button>
                        <Button
                          onClick={() => {
                            setQuickChatCreate(true);
                            setQuickChatEdit(true);
                            setBotData((prevBotData) => ({
                              ...prevBotData,
                              domainName: quickChatDetails?.domain,
                              botName: quickChatDetails?.name,
                            }));
                          }}
                          className="text-12 sm:text-16 w-full font-medium rounded-md py-2 px-4"
                          variant="contained"
                          color="quickChat"
                          type="button"
                          size="small"
                          disabled={!quickChatActiveData?.isActive}
                        >
                          {t('quickChat.chatbotDetail.edit')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BotStatus
                quickChatActiveData={quickChatActiveData}
                setQuickChatActiveData={setQuickChatActiveData}
                whatsAppPhoneNumberData={whatsAppPhoneNumberId}
                callFrom="QuickChat"
              />
            </div>
          )}
        </>
      </div>
      {selectAvatar && (
        <Dialog
          open={selectAvatar}
          onClose={() => setSelectAvatar(false)}
          aria-labelledby="avatar-selection-dialog"
          classes={{
            paper: 'p-14 rounded-md',
          }}
          PaperProps={{
            style: {
              maxWidth: '500px',
            },
          }}
        >
          <DialogTitle id="avatar-selection-dialog" className="text-24 p-10 text-center">
            {t('quickChat.noBot.use.chooseBotImage')}
          </DialogTitle>
          <IconButton
            className="absolute top-4 right-4"
            onClick={() => setSelectAvatar(!selectAvatar)}
            color="inherit"
          >
            <CloseIcon className="w-20 h-20 text-grey-700" />
          </IconButton>

          <DialogContent className="p-14">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              className="gap-20 grid grid-cols-3"
              value={selectAvatarUrl}
              onChange={(event) => {
                handleSelectAvtar(event.target.value);
                setSelectAvatar(false);
              }}
            >
              {avatarList?.length > 0 &&
                avatarList?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item?.avatar}
                    control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />}
                    label={
                      <code className="flex justify-between items-center">
                        <span className="mx-auto rounded-md">
                          <img
                            src={item?.avatar}
                            alt="Avatar"
                            className="max-w-112 bg-indigo-50 bg-opacity-40 max-h-112 mx-auto w-full rounded-md p-0 sm:p-20"
                          />
                        </span>
                      </code>
                    }
                    className={`rounded-md m-0 relative inline-block text-center mx-0 sm:mx-16 ${
                      selectAvatarUrl === item?.avatar
                        ? 'bg-quick-chat-100'
                        : 'bg-white border-grey'
                    }`}
                  />
                ))}
              <label htmlFor="button-file" className="flex justify-center items-center">
                <input
                  accept="image/*"
                  name="button-file"
                  className="opacity-0 w-0 cursor-pointer"
                  id="button-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <Button className="hover:bg-white p-0" component="span">
                  <img
                    src="/assets/images/icon/addImage.png"
                    alt="Add Avatar"
                    className="sm:max-w-96 max-w-82 max-h-82 sm:max-h-96"
                  />
                </Button>
              </label>
            </RadioGroup>
          </DialogContent>
        </Dialog>
      )}
      {shareCompOpen && (
        <SharePost
          image={`${process.env.REACT_APP_PORTAL_URL}/quickChat/${quickChatDetails?.domain}`}
          open={shareCompOpen}
          onClose={handleCloseComp}
        />
      )}
    </div>
  );
};

export default QuickChat;
