import withReducer from 'app/store/withReducer';
import { useEffect, useState, useMemo, useCallback } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icon from '@mui/material/Icon';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import jwtDecode from 'jwt-decode';
import * as React from 'react';
import { publicIpv4 } from 'public-ip';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import history from '@history';
import { useTranslation } from 'react-i18next';
import paymentData from '../query/payment';
import { getEncryptedData, getDecryptData } from '../common/common';
import {
  getPackageDataOnPackageIdAndCountryId,
  getSubcriptionAndAddOnUsingUserId,
  upgradeAndDowngradeSubscription,
  getPackageAddOn,
  checkPromoCode,
} from './store/paymentSlice';
import reducer from './store';

function PaymentUpdate() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();
  const countryData = useMemo(
    () => [
      {
        id: 2,
        name: 'India',
        countryCode: 91,
        isoCode: 'IND',
        isActive: true,
        currency: 'INR',
        symbol: '₹',
        flag: 'https://flagcdn.com/w320/in.png',
        countryId: 2,
      },
      {
        id: 1,
        name: 'United States',
        countryCode: 1,
        isoCode: 'USA',
        isActive: true,
        currency: 'USD',
        symbol: '$',
        flag: 'https://flagcdn.com/w320/us.png',
        countryId: 1,
      },
    ],
    []
  );

  const getPackageData = useSelector(
    ({ paymentReducers }) =>
      paymentReducers?.payment?.getPackageDataOnPackageIdAndCountryIdData?.data
        ?.getPackageDataOnPackageIdAndCountryId || ''
  );

  const getSubcriptionAndAddOnData = useSelector(
    ({ paymentReducer }) =>
      paymentReducer?.payment?.getSubcriptionAndAddOnUsingUserIdData?.data
        ?.getSubcriptionAndAddOnUsingUserId || {}
  );

  let packageDetailsByUserID;

  if (
    Object.keys(getSubcriptionAndAddOnData).length > 0 &&
    getSubcriptionAndAddOnData?.status === 200 &&
    getSubcriptionAndAddOnData?.data
  ) {
    packageDetailsByUserID = JSON.parse(getDecryptData(getSubcriptionAndAddOnData?.data));
  }

  //
  // const getPackageAddOnData = useSelector(
  //   ({ paymentReducers }) =>
  //     paymentReducers?.payment?.getPackageAddOnData?.data?.getPackageAddOn || ''
  // );

  const getPromoCodeData = useSelector(
    ({ paymentReducers }) =>
      paymentReducers?.payment?.checkPromoCodeData?.data?.checkPromoCode || {}
  );
  // let packageAddOnDetails;
  let promoCodeDecryptValue;

  // if (getPackageAddOnData?.data) {
  //   packageAddOnDetails = JSON.parse(getDecryptData(getPackageAddOnData?.data));
  // }

  if (Object.keys(getPromoCodeData).length > 0 && getPromoCodeData?.status === 200) {
    promoCodeDecryptValue = JSON.parse(getDecryptData(getPromoCodeData?.data));
  }

  const [loading, setLoading] = useState(true);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [totalLocation, setTotalLocation] = useState(0);
  const [subscriptionPackageStripePriceId, setSubscriptionPackageStripePriceId] = useState('');
  const [totalAddonAmount, setTotalAddonAmount] = useState(0);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [billingType, setBillingType] = useState('month');
  const [promoCodeValue, setPromoCodeValue] = useState();
  const [promoCodeLoading, setPromoCodeLoading] = useState(false);
  const [promoCodeInfo, setPromoCodeInfo] = useState(false);
  const [ipBasedData, setIpBasedData] = useState();
  const [amountPerLocation, setAmountPerLocation] = useState();
  const [selectedAddOn, setSelectedAddOn] = useState();
  const [packageDetails, setPackageDetails] = useState();
  const [routeToken] = useState(jwtDecode(routeParams?.token));
  const [addOnPrice, setAddonPrice] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [timeRemaining, setTimeRemaining] = useState();
  const [isTimeOver, setIsTimeOver] = useState(false);
  const [successPayment, setSuccessPayment] = useState(false);

  let selectedPackageDetails;
  let calculateDiscountAmount;
  let calculateTotalAmount;
  let withPromoCodeAmount;
  let stripeAmountOff;

  useEffect(() => {
    if (getPackageData && !packageDetails) {
      setPackageDetails(JSON.parse(getDecryptData(getPackageData?.data)));
    }
  }, [getPackageData, packageDetails]);

  const ipAddressData = useCallback(async () => {
    if (routeToken) {
      if (!ipBasedData) {
        setIpBasedData(countryData.find((o) => o.id === routeToken?.countryId));
      }

      if (routeToken?.getWaySupport !== 'razorpay') {
        const data = JSON.stringify({
          countryId: routeToken?.countryId,
          packageId: parseInt(routeToken?.upgradeDowngradePlanTypeId, 10),
        });
        if (ipBasedData && getPackageData?.status !== 200) {
          const payload = {
            query: paymentData.getPackageDataOnPackageIdAndCountryId,
            variables: { data: getEncryptedData(data) },
          };
          await dispatch(getPackageDataOnPackageIdAndCountryId(payload));

          const UserIdData = JSON.stringify({
            userId: parseInt(routeToken?.id, 10),
          });

          const payloadSubscriptionDataUserId = {
            query: paymentData.getSubcriptionAndAddOnUsingUserId,
            variables: { data: getEncryptedData(UserIdData) },
            url: routeParams.token,
          };
          await dispatch(getSubcriptionAndAddOnUsingUserId(payloadSubscriptionDataUserId));
        }
        // if (ipBasedData && getPackageAddOnData?.status !== 200) {
        //   const payloadAddOn = {
        //     query: paymentData.getPackageAddOn,
        //     variables: { data: getEncryptedData(data) },
        //   };
        //   dispatch(getPackageAddOn(payloadAddOn));
        // }
      }

      setLoading(false);
    }
  }, [dispatch, routeToken, ipBasedData, getPackageData, countryData, routeParams.token]);

  useEffect(() => {
    setLoading(true);
    ipAddressData();
  }, [ipAddressData]);

  useEffect(() => {
    const tokenExpiryTimestamp = routeToken?.exp;
    const currentDate = new Date();
    if (tokenExpiryTimestamp * 1000 < currentDate.getTime()) {
      setIsTimeOver(true);
    } else {
      const tokenDate = new Date(tokenExpiryTimestamp * 1000);
      const timeDiff = Math.abs(tokenDate.getTime() - currentDate.getTime());
      const timeDiffInSecond = Math.ceil(timeDiff / 1000);
      setTimeRemaining(timeDiffInSecond);
      const timerInterval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    }
  }, [routeToken.exp]);

  useEffect(() => {
    setLoading(true);
    if (routeToken?.getWaySupport === 'razorpay') {
      history.push(`/payment/${routeParams?.token}`);
    }
  }, [routeParams?.token, routeToken?.getWaySupport]);

  useEffect(() => {
    if (timeRemaining <= 0) {
      setIsTimeOver(true);
    }
  }, [timeRemaining]);

  if (getPackageData?.data) {
    selectedPackageDetails = packageDetails?.find((element) => {
      return element.stripePriceId === subscriptionPackageStripePriceId;
    });
    calculateTotalAmount = parseInt(selectedPackageDetails?.amount, 10);

    // if (totalAddonAmount > 0) {
    //   calculateTotalAmount += parseInt(totalAddonAmount, 10);
    // }

    if (promoCodeInfo && promoCodeDecryptValue && Object?.keys(promoCodeDecryptValue)?.length > 0) {
      if (promoCodeDecryptValue?.coupon?.percent_off) {
        calculateDiscountAmount =
          (calculateTotalAmount * parseInt(promoCodeDecryptValue?.coupon?.percent_off, 10)) / 100;
        withPromoCodeAmount = calculateTotalAmount - parseInt(calculateDiscountAmount, 10);
      } else if (promoCodeDecryptValue?.coupon?.amount_off) {
        const stripeDiscount = {
          amount_off: promoCodeDecryptValue?.coupon?.amount_off,
          currency: promoCodeDecryptValue?.coupon?.currency,
        };
        stripeAmountOff = formatStripeAmount(stripeDiscount);
        withPromoCodeAmount = calculateTotalAmount - parseInt(stripeAmountOff, 10);
      }
    }
  }

  // useEffect(() => {
  //   if (getPackageAddOnData?.data && !selectedAddOn) {
  //     setSelectedAddOn(
  //       packageAddOnDetails?.find((element) => {
  //         console.log('billingType', billingType);
  //         console.log('=== element.packageInterval', element.packageInterval);
  //         return billingType === element.packageInterval;
  //       })
  //     );
  //   }
  // }, [getPackageAddOnData, selectedAddOn, billingType, packageAddOnDetails]);

  // useEffect(() => {
  //   if (!addOnPrice) {
  //     let signalAddOnPrice = '';
  //     signalAddOnPrice = packageAddOnDetails?.find((element) => {
  //       return billingType === element.packageInterval;
  //     });
  //     setAddonPrice(signalAddOnPrice?.stripePriceId);
  //   }
  // }, [billingType, packageAddOnDetails, addOnPrice]);

  useEffect(() => {
    if (
      packageDetails?.length > 0 &&
      packageDetails[0]?.stripePriceId &&
      !subscriptionPackageStripePriceId
    ) {
      setSubscriptionPackageStripePriceId(packageDetails[0]?.stripePriceId);
    }
  }, [packageDetails, subscriptionPackageStripePriceId]);

  useEffect(() => {
    if (getPromoCodeData?.status) {
      if (getPromoCodeData?.status !== 200 && getPromoCodeData?.status !== 401) {
        enqueueSnackbar('coupon code not valid', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      }
      if (getPromoCodeData?.status === 200) {
        enqueueSnackbar(getPromoCodeData?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
        setPromoCodeInfo(true);
      }
      if (getPromoCodeData?.status === 401) {
        enqueueSnackbar(getPromoCodeData?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
        setPromoCodeInfo(true);
      }
    }
  }, [getPromoCodeData, enqueueSnackbar, closeSnackbar]);

  // useEffect(() => {
  //   if (selectedAddOn) {
  //     setTotalAddonAmount(parseInt(totalLocation, 10) * parseInt(selectedAddOn?.amount, 10));
  //   } else {
  //     setTotalAddonAmount(parseInt(totalLocation, 10) * parseInt(amountPerLocation, 10));
  //   }
  // }, [totalLocation, amountPerLocation, selectedAddOn]);

  // if (subscriptionData) {
  //   return (
  //     <Subscribe
  //       location={subscriptionData}
  //       totalPayableAmount={withPromoCodeAmount || calculateTotalAmount}
  //       selectedPackageValue={selectedPackageDetails}
  //       ipBasedValue={ipBasedData}
  //       selectedPackageDetails={selectedPackageDetails}
  //       setSubscriptionData={setSubscriptionData}
  //     />
  //   );
  // }

  if (loading) {
    return <FuseLoading />;
  }

  const handleChangeLocation = (event, amountSingleLocation) => {
    setTotalLocation(event.target.value);
    setAmountPerLocation(amountSingleLocation);
  };

  const handleChangePackage = (event) => {
    let packageInterval = [];
    // let packageAddonData = [];

    setSubscriptionPackageStripePriceId(event.target.value);

    packageInterval = packageDetails?.find((element) => {
      return event.target.value === element.stripePriceId;
    });
    // packageAddonData = packageAddOnDetails?.find((element) => {
    //   return event.target.value === element.stripePriceId;
    // });

    // setAmountPerLocation(amountSingleLocation);

    setBillingType(packageInterval?.packageInterval);
    // const data = packageAddOnDetails?.find((element) => {
    //   return packageInterval?.packageInterval === element.packageInterval;
    // });
    // setSelectedAddOn(data);
  };

  const handleSubscription = async () => {
    try {
      setSubscriptionLoading(true);
      let packagePriceData = {
        priceId: selectedPackageDetails.stripePriceId,
        stripeSubscriptionId: routeToken.stripeSubscriptionId,
        isUpgrade: routeToken?.type === 'upgradeSubscription',
        countryId: ipBasedData?.countryId,
        packageInterval: selectedPackageDetails?.packageInterval,
      };

      if (promoCodeValue) {
        if (promoCodeDecryptValue && Object?.keys(promoCodeDecryptValue)?.length > 0) {
          packagePriceData = {
            ...packagePriceData,
            promotionCode: promoCodeDecryptValue?.id,
          };
        }
      }
      if (totalLocation > 0) {
        packagePriceData = {
          ...packagePriceData,
          // addOn: [
          //   {
          //     priceId: addOnPrice,
          //     quantity: totalLocation,
          //   },
          // ],
        };
      }
      // return;
      const data = JSON.stringify(packagePriceData);

      const registrationPayload = {
        query: paymentData.upgradeAndDowngradeSubscription,
        variables: { data: getEncryptedData(data) },
        url: routeParams.token,
      };
      const result = await dispatch(upgradeAndDowngradeSubscription(registrationPayload));
      if (result?.payload?.data?.upgradeAndDowngradeSubscription?.status !== 200) {
        enqueueSnackbar(result?.payload?.data?.upgradeAndDowngradeSubscription?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      } else {
        setSuccessPayment(true);
      }
      setSubscriptionLoading(false);
    } catch (error) {
      console.log(error, 'error');
    }
  };
  const handleApplyCoupon = async () => {
    setPromoCodeLoading(true);
    const data = JSON.stringify({
      promoCode: promoCodeValue,
      amount: calculateTotalAmount,
      stripePriceId: selectedPackageDetails?.stripePriceId,
    });
    try {
      const payload = {
        query: paymentData.checkPromoCode,
        variables: { data: getEncryptedData(data) },
        url: routeParams.token,
      };
      await dispatch(checkPromoCode(payload));
    } catch (error) {
      console.log(error, 'error');
    }
    setPromoCodeLoading(false);
  };

  function clearPromoCode() {
    setPromoCodeInfo(false);
  }

  function formatStripeAmount(discount) {
    const formattedAmount = (discount.amount_off / 100)
      .toLocaleString(undefined, {
        currency: discount.currency,
      })
      .replace(/,/g, '');
    return parseInt(formattedAmount, 10);
  }

  const handleBackToApp = () => {
    history.push('subscription://quickreviewsSubscriptionSuccess');
  };

  if (successPayment) {
    return <Success type={routeToken?.type} />;
  }

  return (
    <>
      <div className="text-center items-center flex mx-auto rounded-md w-full md:w-auto md:min-w-586">
        <div className="w-full rounded-md m-16" style={{ backgroundColor: '#FAFBFC' }}>
          <div className="bg-white flex items-center justify-between px-16 sm:px-32 py-16  rounded-t-md">
            <img src="assets/images/logo/logo.png" className="w-[140px]" alt="logo" />
          </div>
          {isTimeOver ? (
            <div className="p-28">
              <img
                src="assets/images/icon/session_out.png"
                className="mx-auto img-fluid"
                alt="..."
              />
              <h2 className="font-semibold text-24 py-16">Your payment session has timed out.</h2>
              <p className="max-w-512 text-18 font-medium mb-10">
                For security reasons, the payment session has expired. Please initiate the payment
                process again by clicking the "Go To Home" button below. Remember, you have 10
                minutes to complete the payment. If you encounter any issues, feel free to contact
                our support team.
              </p>
              <p className="max-w-512 text-18 font-medium mb-28">
                Thank you for your understanding and cooperation.
              </p>
              <Button
                variant="contained"
                color="secondary"
                className="w-full max-w-320 rounded-md text-18 sm:text-20 font-semibold"
                type="button"
                onClick={handleBackToApp}
              >
                Go To Home
              </Button>
            </div>
          ) : (
            <>
              <p style={{ backgroundColor: '#FFFAE5' }} className="py-10 font-medium text-16">
                Please complete the process within{' '}
                <span className="text-red">
                  {Math.floor(timeRemaining / 60)}:
                  {(timeRemaining % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}{' '}
                  seconds.
                </span>
              </p>
              <div className="text-left px-16 sm:px-32">
                <div className="my-28">
                  <h1 className="font-semibold text-24 mb-5">
                    {routeToken?.type === 'upgradeSubscription'
                      ? `Upgrade To ${routeToken?.upgradeDowngradePlanType} `
                      : `Downgrade To ${routeToken?.upgradeDowngradePlanType}`}
                  </h1>
                  <Typography className="mb-0 text-16 md:text-20 font-medium">
                    Subscription will renew automatically
                  </Typography>
                </div>
                <div className="bg-white mt-16 py-12 px-14 rounded-md leading-loose mb-28">
                  <h3 className="text-16 font-semibold">Active Plan</h3>
                  <div className="flex justify-between items-center text-18 font-medium">
                    <span className="text-grey-700">
                      {packageDetailsByUserID?.subcription?.name} Plan
                    </span>
                    <h4 className="text-18 font-semibold capitalize">
                      {ipBasedData?.symbol}
                      {packageDetailsByUserID?.subcription?.amount} /{' '}
                      {packageDetailsByUserID?.subcription?.packageInterval}
                    </h4>
                  </div>
                </div>
                <div className="">
                  <FormControl className="block">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-20 lg:gap-28"
                      value={subscriptionPackageStripePriceId}
                      onChange={handleChangePackage}
                    >
                      {packageDetails &&
                        packageDetails?.length > 0 &&
                        packageDetails?.map((packageValue, index) => (
                          <FormControlLabel
                            key={index}
                            value={packageValue?.stripePriceId}
                            sx={{
                              '& .Mui-checked ~ .MuiFormControlLabel-root': {
                                backgroundColor: 'red',
                              },
                            }}
                            control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24" />}
                            label={
                              <code>
                                <span className="block text-16 font-medium mb-5 capitalize">
                                  {packageValue?.packagePeriod} {packageValue?.packageInterval}
                                </span>
                                <span className="block text-18 font-bold mb-5 capitalize">
                                  {ipBasedData?.symbol}
                                  {packageValue?.amount}/{packageValue?.packageInterval}
                                </span>
                                <span className="block text-12 sm:text-14 font-medium">
                                  {packageValue?.packageInterval === 'month'
                                    ? 'Billed monthly'
                                    : 'Billed annually'}
                                </span>
                              </code>
                            }
                            className="bg-white rounded-md p-16 border m-0 relative"
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                </div>
                {/* <div className="mt-28">*/}
                {/*  <Accordion className="shadow-none border">*/}
                {/*    /!*<AccordionSummary*!/*/}
                {/*    /!*  expandIcon={<ExpandMoreIcon className="text-darkgreen" />}*!/*/}
                {/*    /!*  aria-controls="panel1a-content"*!/*/}
                {/*    /!*  id="panel1a-header"*!/*/}
                {/*    /!*  className="py-12 px-14"*!/*/}
                {/*    /!*  sx={{*!/*/}
                {/*    /!*    '& .MuiAccordionSummary-content, &.Mui-expanded': { m: '0 !important' },*!/*/}
                {/*    /!*    '&.Mui-expanded': { minHeight: '48px', borderBottom: '1px dashed #A5D6A7' },*!/*/}
                {/*    /!*  }}*!/*/}
                {/*    /!*>*!/*/}
                {/*    /!*  <Typography className="text-16 font-semibold">*!/*/}
                {/*    /!*    {' '}*!/*/}
                {/*    /!*    <Icon className="text-20 align-middle mr-12 text-darkgreen">*!/*/}
                {/*    /!*      library_add*!/*/}
                {/*    /!*    </Icon>*!/*/}
                {/*    /!*    Add-Ons*!/*/}
                {/*    /!*  </Typography>*!/*/}
                {/*    /!*</AccordionSummary>*!/*/}
                {/*    /!*<AccordionDetails>*!/*/}
                {/*    /!*  {packageAddOnDetails &&*!/*/}
                {/*    /!*    packageAddOnDetails?.length > 0 &&*!/*/}
                {/*    /!*    packageAddOnDetails?.map((packageAddOnValue, index) => (*!/*/}
                {/*    /!*      <span key={index}>*!/*/}
                {/*    /!*        {billingType === packageAddOnValue.packageInterval && (*!/*/}
                {/*    /!*          <div className="flex items-center justify-between">*!/*/}
                {/*    /!*            <span>*!/*/}
                {/*    /!*              <h4 className="text-darkgreen font-medium text-16">Location</h4>*!/*/}
                {/*    /!*              <span className="font-medium text-12 sm:text-14 mt-6 block capitalize">*!/*/}
                {/*    /!*                {ipBasedData?.symbol}*!/*/}
                {/*    /!*                {packageAddOnValue?.amount}*!/*/}
                {/*    /!*                /Location/*!/*/}
                {/*    /!*                {packageAddOnValue?.packageInterval}*!/*/}
                {/*    /!*              </span>*!/*/}
                {/*    /!*            </span>*!/*/}
                {/*    /!*            <FormControl*!/*/}
                {/*    /!*              className="sm:min-w-120 sm:w-auto w-96 mx-0 extra-small-select"*!/*/}
                {/*    /!*              sx={{ m: 1 }}*!/*/}
                {/*    /!*            >*!/*/}
                {/*    /!*              <Select*!/*/}
                {/*    /!*                value={totalLocation}*!/*/}
                {/*    /!*                onChange={(e) =>*!/*/}
                {/*    /!*                  handleChangeLocation(e, packageAddOnValue?.amount)*!/*/}
                {/*    /!*                }*!/*/}
                {/*    /!*                size="small"*!/*/}
                {/*    /!*                className="font-semibold text-12 sm:text-14"*!/*/}
                {/*    /!*                sx={{ '& .MuiSelect-select': { lineHeight: '20px' } }}*!/*/}
                {/*    /!*                displayEmpty*!/*/}
                {/*    /!*                inputProps={{ 'aria-label': 'Without label' }}*!/*/}
                {/*    /!*              >*!/*/}
                {/*    /!*                <MenuItem value={0}>Select Location</MenuItem>*!/*/}
                {/*    /!*                <MenuItem value={1}>1 Location</MenuItem>*!/*/}
                {/*    /!*                <MenuItem value={2}>2 Location</MenuItem>*!/*/}
                {/*    /!*                <MenuItem value={3}>3 Location</MenuItem>*!/*/}
                {/*    /!*                <MenuItem value={4}>4 Location</MenuItem>*!/*/}
                {/*    /!*                <MenuItem value={5}>5 Location</MenuItem>*!/*/}
                {/*    /!*              </Select>*!/*/}
                {/*    /!*            </FormControl>*!/*/}
                {/*    /!*            {totalLocation !== 0 && (*!/*/}
                {/*    /!*              <h5 className="text-16 md:text-20 font-semibold">*!/*/}
                {/*    /!*                {ipBasedData?.symbol}*!/*/}
                {/*    /!*                {totalAddonAmount}*!/*/}
                {/*    /!*              </h5>*!/*/}
                {/*    /!*            )}*!/*/}
                {/*    /!*          </div>*!/*/}
                {/*    /!*        )}*!/*/}
                {/*    /!*      </span>*!/*/}
                {/*    /!*    ))}*!/*/}
                {/*    /!*</AccordionDetails>*!/*/}
                {/*  </Accordion>*/}
                {/* </div>*/}
                <div className="mt-12">
                  <Accordion className="shadow-none border">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="text-darkgreen" />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      className="py-12 px-14"
                      sx={{
                        '& .MuiAccordionSummary-content, &.Mui-expanded': { m: '0 !important' },
                        '&.Mui-expanded': { minHeight: '48px', borderBottom: '1px dashed #A5D6A7' },
                      }}
                    >
                      <Typography className="text-16 font-semibold">
                        {' '}
                        <Icon className="text-20 align-middle mr-12 text-darkgreen">
                          library_add
                        </Icon>
                        Apply coupon
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="pb-15" style={{ padding: '15px' }}>
                      <div>
                        {promoCodeDecryptValue && promoCodeInfo ? (
                          <div>
                            <span className="bg-grey-200 rounded-md font-semibold text-14  py-8 px-12 inline-block items-center">
                              <img
                                src="assets/images/icon/sell_tag.png"
                                className="h-12 w-12 mr-5 mt-5 float-left"
                                alt="..."
                              />{' '}
                              {promoCodeValue}
                              <span
                                className="inline-block relative top-4 ml-10"
                                onClick={() => clearPromoCode()}
                                aria-hidden="true"
                                role="button"
                              >
                                <Icon className="text-16 text-grey-600">close</Icon>
                              </span>
                            </span>
                            <span className="block mt-4 text-12 text-grey-600">
                              {promoCodeDecryptValue?.coupon?.percent_off
                                ? `${promoCodeDecryptValue?.coupon?.percent_off}%`
                                : `${ipBasedData?.symbol}${stripeAmountOff}`}{' '}
                              off
                            </span>
                          </div>
                        ) : (
                          <FormControl variant="standard" className="relative w-full">
                            <TextField
                              placeholder="Enter coupon code"
                              size="small"
                              variant="outlined"
                              className="w-full rounded-md"
                              value={promoCodeValue}
                              onChange={(e) => setPromoCodeValue(e.target.value)}
                              id="bootstrap-input"
                            />

                            <Button
                              variant="text"
                              className={`rounded-md max-w-120 inline-block border-0 hover:border-0 hover:border-0 font-bold absolute -right-2 top-0 -bottom-1 rounded-l-none rounded-r-md ${
                                promoCodeValue
                                  ? 'scale-100 opacity-1'
                                  : 'scale-0 ease-in-out duration-300 opacity-0'
                              }`}
                              color="secondary"
                              disabled={!promoCodeValue || promoCodeLoading}
                              onClick={() => {
                                handleApplyCoupon();
                              }}
                            >
                              Apply{' '}
                              {promoCodeLoading && (
                                <CircularProgress
                                  size={18}
                                  className="text-darkgreen absolute mx-auto right-20 top-12"
                                />
                              )}
                            </Button>
                          </FormControl>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="bg-white border mt-16 py-12 px-14 rounded-md leading-loose">
                  <div className="flex items-center justify-between">
                    <span className="text-14 sm:text-16 text-grey-600 text-black font-semibold">
                      {routeToken?.upgradeDowngradePlanType} Plan
                    </span>
                    <span className="text-14 sm:text-16 font-semibold">
                      {ipBasedData?.symbol}
                      {selectedPackageDetails?.amount}
                    </span>
                  </div>
                  {/* {totalLocation !== 0 && (*/}
                  {/*  <div className="flex items-center justify-between">*/}
                  {/*    <span className="text-14 sm:text-16 text-grey-600 font-medium">*/}
                  {/*      Add-Ons price*/}
                  {/*    </span>*/}
                  {/*    <span className="text-14 sm:text-16 font-semibold">*/}
                  {/*      {' '}*/}
                  {/*      {ipBasedData?.symbol}*/}
                  {/*      {parseInt(totalAddonAmount, 10)}*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/* )}*/}
                  {(calculateDiscountAmount || stripeAmountOff) && (
                    <div className="flex items-center justify-between">
                      <span className="text-16 text-grey-600 font-medium">Coupon</span>
                      <span className="text-16 font-semibold">
                        - {ipBasedData?.symbol} {calculateDiscountAmount || stripeAmountOff}
                      </span>
                    </div>
                  )}
                  <div className="flex items-center justify-between">
                    <span className="text-14 sm:text-16 text-grey-600 text-black font-semibold">
                      Total amount
                    </span>
                    <span className="text-14 sm:text-16 text-black font-semibold capitalize">
                      {ipBasedData?.symbol}
                      {withPromoCodeAmount || calculateTotalAmount}/
                      {selectedPackageDetails?.packageInterval}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-14 sm:text-16 text-grey-600 text-black font-semibold">
                      Next recurring amount
                    </span>
                    <span className="text-14 sm:text-16 text-black font-semibold capitalize">
                      {ipBasedData?.symbol}
                      {calculateTotalAmount}/{selectedPackageDetails?.packageInterval}
                    </span>
                  </div>
                </div>
                <Button
                  variant="contained"
                  className="rounded-md w-full text-18 sm:text-20 font-semibold mt-28"
                  onClick={() => handleSubscription()}
                  disabled={subscriptionLoading}
                  color="secondary"
                >
                  {routeToken?.type === 'upgradeSubscription' ? 'Upgrade Plan ' : 'Downgrade Plan '}{' '}
                  {subscriptionLoading && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
                <div className="text-16 text-medium text-center my-20">
                  You can cancel the subscription anytime
                </div>
              </div>
              <div className="bg-white py-10">
                By continuing you agree to our <br />
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.privacyPolicy')}
                </a>
                ,{' '}
                <a
                  href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  Subscriber Agreement.
                </a>
                ,{' '}
                <a
                  href={process.env.REACT_APP_EULA_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.eula')}
                </a>{' '}
                and{' '}
                <a
                  href={process.env.REACT_APP_DISCLAIMER_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.disclaimers')}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

function Success({ type }) {
  useEffect(() => {
    return () => {
      setTimeout(() => {
        history.push('subscription://quickreviewsSubscriptionSuccess');
      }, 2000);
    };
  }, []);

  const handleBackToApp = () => {
    history.push('subscription://quickreviewsSubscriptionSuccess');
    // const deepLink = 'quickreviews.click://PlanDetailsScreen';
    // const newWindow = window.open(deepLink, '_blank');
    // if (newWindow) {
    //   newWindow.focus();
    // } else {
    //   // Handle cases where the deep link couldn't be opened (e.g., app not installed)
    //   console.log('Failed to open the app. Please make sure the app is installed.');
    // }
    // window.open('about:blank', '_self');
    // window.close(); // This will close the tab if the user confirms.
  };

  return (
    <div
      className="text-center md:h-screen items-center text-center md:flex mx-auto rounded-md"
      style={{ maxWidth: '586px' }}
    >
      <div className="rounded-md m-16 sm:m-0" style={{ backgroundColor: '#FAFBFC' }}>
        <div className="bg-white px-16 sm:px-32 py-16  rounded-t-md">
          <img src="assets/images/logo/logo.png" className="w-[170px]" alt="logo" />
        </div>
        <div className="px-16 sm:px-32 mt-68 pb-32">
          <img src="assets/images/icon/done.png" className="h-144 w-144 mx-auto mb-28" alt="..." />
          <h2 className="text-24 font-semibold">
            {type === 'upgradeSubscription'
              ? 'Plan Upgrade Successfully'
              : 'Plan Downgrade Successfully'}
          </h2>
          <Typography className="md:text-18 text-16 mt-12 text-medium mb-40 max-w-400">
            Your plan will be{' '}
            {type === 'upgradeSubscription'
              ? 'upgrade in an hour'
              : 'downgraded in the next billing cycle.'}{' '}
            You will retain your current plan's features until then. If you have any questions or
            need assistance, please contact our support team.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className="w-full rounded-md text-18 sm:text-20 font-semibold"
            type="button"
            onClick={handleBackToApp}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}

function Failed() {
  const handleBackToApp = () => {
    window.location.href = 'URL_of_Your_App';
  };

  return (
    <div
      className="text-center md:h-screen items-center text-center md:flex mx-auto rounded-md"
      style={{ maxWidth: '586px' }}
    >
      <div className="rounded-md m-16 sm:m-0" style={{ backgroundColor: '#FAFBFC' }}>
        <div className="bg-white px-16 sm:px-32 py-16  rounded-t-md">
          <img src="assets/images/logo/logo.png" className="w-[170px]" alt="logo" />
        </div>
        <div className="px-16 sm:px-32 mt-68 pb-32">
          <img
            src="assets/images/icon/attention.png"
            className="h-144 w-144 mx-auto mb-28"
            alt="..."
          />
          <h2 className="text-24 font-semibold">Payment Failed!</h2>
          <Typography className="md:text-18 text-16 mt-12 text-medium mb-40 max-w-400">
            Oops! It seems there was an issue with your payment. Please check your payment
            information and try again.
          </Typography>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-20 lg:gap-20">
            <Button
              variant="outlined"
              color="secondary"
              className="w-full rounded-md text-18 sm:text-20 font-semibold"
              type="button"
              onClick={handleBackToApp}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="w-full rounded-md text-18 sm:text-20 font-semibold"
              type="button"
              // onClick={() => <Payment />}
            >
              Retry Payment
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withReducer('paymentReducers', reducer)(PaymentUpdate);
